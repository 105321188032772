import React from 'react'
import { Link } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import * as ROUTES from '../constants/routes'
import '../sass/marginals.scss'
import {ReactComponent as Logo} from '../assets/logo.svg'
import {FiLinkedin, FiMail, FiMapPin, FiMenu, FiPhone} from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { useMatch } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import headerBG_1 from '../assets/header-bg-1.svg'

const SharedLayout = () => {

  const {pathname} = useLocation()

  const Match = (route) => {
    return useMatch(route,pathname)
  }

  return (
    <div>
        <div className='marginal header '>
          {!Match(ROUTES.PROJECTS) && <div className='bg d-none d-lg-flex justify-content-start align-items-start'>
            <img src={headerBG_1}/>
          </div>}
          <div className='quick-info d-none d-lg-flex'>
            <ul className='col-10 p-0 d-flex flex-row justify-content-end align-items-center'>
              <li><FiPhone size={15}/>+(251) 114 701 791</li>
              <li><FiMail size={15}/><a href='mailto:info@gandyengineering.com'>info@gandyengineering.com</a></li>
            </ul>
          </div>
          <Navbar className='head-content col-10 d-flex flex-row align-items-center justify-content-between' expand='lg' >
            <Link to={ROUTES.HOME} className="navbar-brand col-9 col-lg-5 p-0">
              <div className='logo d-flex flex-row align-items-center'>
                  <Logo/>
                  {/* <h4 className='d-flex d-none d-lg-flex'>G&amp;Y</h4> */}
                  <h5>Engineering Consult PLC</h5>
              </div>
            </Link>
            <Navbar.Toggle className="navbar-toggler col-2 justify-content-center align-items-center" aria-controls="header-nav">
              <FiMenu/>
            </Navbar.Toggle>
            <Navbar.Collapse className='nav-list col-12 col-lg-6 justify-content-lg-end align-items-center' id="header-nav">
              <ul className="navbar-nav mr-auto">
                <li className={`nav-item ${Match(ROUTES.HOME)?'active':''}`}>
                  <Link to={ROUTES.HOME} className='nav-link'>Home</Link>
                </li>
                <li className={`nav-item ${Match(ROUTES.ABOUT)?'active':''}`}>
                  <Link to={ROUTES.ABOUT} className='nav-link'>About</Link>
                </li>
                <li className={`nav-item ${Match(ROUTES.PROJECTS)?'active':''}`}>
                  <Link to={ROUTES.PROJECTS} className='nav-link'>Projects</Link>
                </li>
                <li className={`nav-item ${Match(ROUTES.SERVICES)?'active':''}`}>
                  <Link to={ROUTES.SERVICES} className='nav-link'>Services</Link>
                </li>
                <li className={`nav-item ${Match(ROUTES.CONTACT)?'active':''}`}>
                  <Link to={ROUTES.CONTACT} className='nav-link'>Contact</Link>
                </li>
                <li className={`d-inline-flex d-lg-none flex-row`}><FiPhone size={15}/>+(251) 114 701 791</li>
                <li className={`d-inline-flex d-lg-none flex-row`}><FiMail size={15}/>info@gyeng.com</li>
              </ul>
            </Navbar.Collapse>
          </Navbar>
        </div>

      <Outlet /> {/* Basically Magic That renders the other components */}
      
      <div className='marginal footer d-flex flex-column justify-content-center align-items-center'>
        <div className='content col-10 d-flex flex-column flex-lg-row justify-content-between'>
          <div className='col-12 col-lg-5 p-0'>
              <Link to={ROUTES.HOME}>
                <div className='logo d-flex flex-column flex-lg-row align-items-lg-center'>
                    <Logo/>
                  <div>
                    <h4 className='d-flex d-none d-lg-flex'>G&amp;Y</h4>
                    <p>Engineering Consult PLC</p>
                  </div>
                </div>
              </Link>
            <p className='desc'>We are a multi-disciplinary Engineering Consultancy firm focused on urban and rural, engineering and infrastructure development projects</p>
          </div>
          <div className='list col-12 col-md-4 col-lg-3'>
            <p className='bold'>
              More About G&amp;Y
            </p>
            <ul>
              <li><Link to={ROUTES.PROJECTS}>Projects</Link></li>
              <li><Link to={ROUTES.ABOUT}>About</Link></li>
              <li><Link to={ROUTES.SERVICES}>Services</Link></li>
              <li><Link to={ROUTES.CONTACT}>Contact</Link></li>
            </ul>
          </div>
          <div className='list col-12 col-md-4 col-lg-3'>
            <p className='bold'>
              How To Get In Touch
            </p>
            <ul>
              <li><FiPhone size={15}/>+(251) 114 701 791</li>
              <li><FiMail size={15}/>info@gyeng.com</li>
              <li><FiMapPin size={15}/>Bole, Warka Tower, 10th Floor</li>
              <li><FiLinkedin size={15}/>gyeng@linkedin.com</li>
            </ul>
          </div>
        </div>
        <div className='info col-10 d-flex flex-column flex-lg-row justify-content-between'>
          <div className='col-12 col-lg-6 p-0 order-1 order-lg-0'>Copyright 2022 G&amp;Y Engineering Consult, All Rights Reserved</div>
          <div className='col-12 col-lg-3 order-0 order-lg-1'>Developed by <a href='https://www.mbandevelopers.com'>MBAN</a></div>
        </div>
      </div>
    </div>
  )
}

export default SharedLayout