import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import '../sass/projects.scss'
import turmiomo from '../assets/projects/turmiomo.png'
import wenchidendi from '../assets/projects/wenchidendi.jpg'
import yalohalal from '../assets/projects/yelohalal.mp4'
import gradient_bg from '../assets/gradient-bg.svg'
import gradient_bgmob from '../assets/gradient-bgmob.svg'
import gy_dark from '../assets/gy-watermark-dark.svg'
import gy_light from '../assets/gy-watermark-light.svg'
import projects from '../assets/projects.json'
import obora1 from '../assets/projects/obora1.jpg'
import obora2 from '../assets/projects/obora2.jpg'
import felegeselam from '../assets/projects/felegeselam.JPG'
import lare from '../assets/projects/lare.jpg'
import harowenchi from '../assets/projects/harowenchi.jpg'

const { incompleteProjects, completedProjects } = projects

const previewProjects = [
  {
    name:'Turmi-Omo Road Project',
    client:"Rama Construction PLC",
    location:'Southern Ethiopia',
    length:'60KM',
    type:'Detail Engineering Design',
    duration:'6 Months',
    status:'Completed',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${turmiomo})`}}></div>
  },
  {
    name:'Yalo Halala Kela Road Project',
    client:"Ethiopian Roads Administration (ERA)",
    location:'SNNPRS, Ethiopia',
    length:'14.5KM',
    type:'Design Detail Engineering',
    duration:'60 Days',
    status:'Completed',
    time:25,
    media:<div className='video-container'> 
      <video autoPlay muted className='image col-12 col-lg-7 p-0 '>
            <source src={yalohalal} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
    </div>
  },
  {
    name:'Wenchi-Dendi Ecotourism Road Network',
    client:"Wenchi Dendi Project Office",
    location:'Oromia Regional State, Ethiopia',
    length:'32KM',
    type:'Construction Work Supervision',
    budget:'30,747,228 ETB',
    duration:'450 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${wenchidendi})`}}></div>
  },
  {
    name:'Lare – New Jikawo – Ninighang Road Project ',
    client:"Ethiopian Roads Administration(ERA)",
    location:'Gambela National Regional State, Ethiopia',
    length:'35KM',
    type:'Construction Work Supervision',
    budget: '25,391,472.33 ETB',
    duration:'1095 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${lare})`}}></div>
  },
  {
    name:'Haro Wenchi CaterLake Access Road Project',
    client:"Ethiopian Roads Administration(ERA)",
    location:'Oromia Regional State, Ethiopia',
    length:'13KM Asphalt & 5KM Cobble Stone ',
    type:'Construction Work Supervision',
    budget: '11,489,650 ETB',
    duration:'180 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${harowenchi})`}}></div>
  },
  {
    name:`Tender Document Preparation for the Remaining Works and Landslide Prevention Measures for Bonga – Ameya – Chida Road Upgrading Project, Contract 2: Felegeselam – Ameya – Chida `,
    client:"Ethiopian Roads Administration(ERA)",
    location:'South Regional State, Ethiopia',
    length:'57.34 KM',
    type:'Design Detail Engineering',
    duration:'180 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${felegeselam})`}}></div>
  },
  {
    name:'Obora – Sidan – Tuluwayu Road Project Contract I ',
    client:"Oromia Roads Logistics Bureau (ORLB) ",
    location:'Oromia Regional State, Ethiopia',
    length:'131.5KM',
    type:'Construction Work Supervision',
    budget: '10,895,675 ETB',
    duration:'721 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${obora1})`}}></div>
  },
  {
    name:'Obora – Sidan – Tuluwayu Road Project Contract II ',
    client:"Ethiopian Roads Administration(ERA)",
    location:'Oromia Regional State, Ethiopia',
    length:'30KM',
    type:'Construction Work Supervision',
    budget: '10,895,675 ETB',
    duration:'721 Days',
    status:'On Going',
    time:5,
    media:<div className='image col-12 col-lg-7 p-0 ' style={{backgroundImage: `url(${obora2})`}}></div>
  },
]



const Projects = () => {

  const [tab,setTab] = useState(0)
  const [projectsList,setProjectsList] = useState(completedProjects)
  const [slide,setSlide] = useState(0)
  const [counter,setCounter] = useState(previewProjects[slide].time)

  const observer = new IntersectionObserver((entries)=>{
    entries.forEach(entry=>{
      if(entry.isIntersecting){
        entry.target.classList.add('show')
      }
      else{
        entry.target.classList.remove('show')
      }
    })
  },{threshold:[0.5,1]})

  useEffect(()=>{
    const cards = Array.from(
      document.querySelectorAll('.list-item')
    )

    cards.forEach(el=>{
      observer.observe(el)
    })


  },[])

  useEffect(()=>{
    if(counter>0){
      setTimeout(()=>setCounter(counter-1),1000)
    }
    else{
      setSlide((slide+1)%previewProjects.length)
    }
  },[counter])

  useEffect(()=>{
    
    setCounter(previewProjects[slide].time)
  },[slide])

  const renderListItem= (item,id) =>{

      
          return <Accordion.Item eventKey={`${id}`} key={id} className='list-item '>
              <Accordion.Header><FiChevronDown size={25} />{item.name} </Accordion.Header>
              <Accordion.Body>
                <dl>
                  <div>
                    <dt>Client Name</dt>
                    <dd>{item.client}</dd>
                  </div>
                  <div>
                    <dt>Location</dt>
                    <dd>{item.location}</dd>
                  </div>
                  <div>
                    <dt>Length</dt>
                    <dd>{item.length}</dd>
                  </div>
                  <div>
                    <dt>Type</dt>
                    <dd>{item.type}</dd>
                  </div>
                  <div>
                    <dt>Duration</dt>
                    <dd>{item.duration}</dd>
                  </div>
                  {item.budget&&
                    <div>
                        <dt>Budget</dt>
                        <dd>{item.budget}</dd>
                    </div>
                  } 
                </dl>
              </Accordion.Body>
            </Accordion.Item>
  }

  return (
    <div className='projects'>
      <div className='section-1'>
        <div className='bg-watermark'>
          <img src={gradient_bg} className='d-none d-lg-flex'/>
          <img src={gradient_bgmob} className='d-flex d-lg-none'/>
        </div>
        <div className='title col-12 p-0 d-flex flex-row'>
          <div className='content col-12 col-lg-8 d-flex flex-row justify-content-center justify-content-lg-start align-items-center'>
            <h2>Projects</h2>
          </div>
          <div className='block col-1 p-0 d-none d-lg-flex'></div>
        </div>
        <div className='slide col-10 p-0'>
          <div className='content col-12 p-0 d-flex flex-column flex-lg-row'>
            {previewProjects[slide].media}
            <div className='desc col-12 col-lg-5 p-0 '>
              <div className='d-flex flex-column text-start'>

                <h5>{previewProjects[slide].name}</h5>
                <dl>
                  <div>
                    <dt>Client Name</dt>
                    <dd>{previewProjects[slide].client}</dd>
                  </div>
                  <div>
                    <dt>Location</dt>
                    <dd>{previewProjects[slide].location}</dd>
                  </div>
                  <div>
                    <dt>Length</dt>
                    <dd>{previewProjects[slide].length}</dd>
                  </div>
                  <div>
                    <dt>Type</dt>
                    <dd>{previewProjects[slide].type}</dd>
                  </div>
                  <div>
                    <dt>Duration</dt>
                    <dd>{previewProjects[slide].duration}</dd>
                  </div>
                  {previewProjects[slide].budget&&
                    <div>
                        <dt>Budget</dt>
                        <dd>{previewProjects[slide].budget}</dd>
                    </div>
                  }
                  <div>
                    <dt>Status</dt>
                    <dd>{previewProjects[slide].status}</dd>
                  </div>

                </dl>
              </div>
            </div>
          </div>
          <div className='counter col-lg-7'>
            {previewProjects.map((item,id)=>{
              return <div className={`dot ${slide===id?'active':''}`} onClick={()=>setSlide(id)}></div>
            })}
          </div>
        </div>
        
      </div>
      <div className='section-2'>
        <div className='tabs d-flex flex-row col-12 col-lg-8 col-xl-7 justify-content-evenly'>
          <div className={` ${tab===0?'active':''}`} onClick={()=>{
            if(tab!==0){
              setTab(0)
              setProjectsList(completedProjects)
            } 
          }}>
            <p>Completed</p>
            <p>{completedProjects.length} Projects</p>
          </div>
          <div className={` ${tab===1?'active':''}`} onClick={()=>{
            if(tab!==1){
              setTab(1)
              setProjectsList(incompleteProjects)
            } 
          }}>
            <p>Incomplete</p>
            <p>{incompleteProjects.length} Projects</p>
          </div>
        </div>
        <div className={`list col-10 m-auto ${tab===0?'d-flex':'d-none'}`}>
          <Accordion defaultActiveKey="0" className='col-12 col-6 p-0'>
            {
              completedProjects.map((item,id)=>{
                return renderListItem(item,id)
              })
            }
          </Accordion>
        </div>
        <div className={`list col-10 m-auto ${tab===1?'d-flex':'d-none'}`}>
          <Accordion defaultActiveKey="0" className='col-12 col-6 p-0'>
            {
              incompleteProjects.map((item,id)=>{
                return renderListItem(item,id)
              })
            }
          </Accordion>
        </div>
      </div>
        <div className='bg-watermark section2'>
          <img src={gy_dark} className='d-none d-lg-flex'/>
        </div>
    </div>
  )
}

export default Projects