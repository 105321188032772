import React, { useState } from 'react'
import { FiPhone, FiMail, FiLinkedin, FiMapPin } from 'react-icons/fi'
import '../sass/contact.scss'

const Contact = () => {
  const [formInput, setFormInput] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  })

  const [error, setError] = useState('')

  const handleSubmit = (e) => {
    if(
      formInput.fullName === '' ||
      formInput.subject === '' ||
      formInput.message === '') {
      e.preventDefault();
      setError('Incomplete Form')
    } 
    else {
      setError('')
      document.getElementById('form').reset()
    }
  }

  const handleChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    })
  }

  return (
    <main className='contact-page'>
      <section className='title d-flex justify-content-between'>
        <div className='title-left col-12 col-lg-6 text-left'>
          <h1>Contact</h1>
        </div>
        <div className='title-right col-1'>

        </div>
      </section>
      <section className='contact-section d-flex flex-column align-items-center align-items-lg-center flex-lg-row  col-10 m-auto'>
        <form action={'mailto:info@gyengineering.com?subject='+encodeURIComponent(formInput.subject)+'&email='+encodeURIComponent(formInput.email)+'&body='+encodeURIComponent(formInput.message)} method="POST" encType="text/plain" name="g&y-consult.com" id="form" onSubmit={handleSubmit} className='d-flex flex-column mb-5 mb-lg-0'>
          <h3>Send an Email</h3>
          {error && <span className='message text-danger'>Fill out all the fields!</span>}

          <input name='fullName' type='text' placeholder='Full Name' onChange={(e)=>handleChange(e)} />
          <input name='subject' type='text' placeholder='Subject' onChange={(e)=>handleChange(e)} />
          <textarea name='message' rows={6} placeholder='Message' onChange={(e)=>handleChange(e)} />
          <button type='submit'>Submit</button>
        </form>
        <div className="vertical-line">
          <div className="line"></div>
          <div className="word-wrapper">
            <div className="word">or</div>
          </div>
        </div>
        <div className='horizontal-line'>
          <p><span>or</span></p>
        </div>
        <form className='info-section d-flex flex-column align-self-lg-start'>
          <h3>Find our info</h3>
          <div className='info-gy'>
            <p><FiPhone />+(251) 114 701 791</p>
            <p><FiMail /><a href='mailto:info@gandyengineering.com'>info@gandyengineering.com</a></p>
            <p><FiLinkedin /><a href='#'>g&yeng@linkedin.com</a></p>
            <p><FiMapPin /><a href='#map'>Bole, Warka Tower, 10th Floor</a></p>
          </div>
          <div className='info-management'>
            <h5>Design Department</h5>
            <p><FiMail /><a href='mailto:desing@gandyengineering.com'>design@gandyengineering.com</a></p>
          </div>
          <div className='info-hr'>
            <h5>Project Formulation Department</h5>
            <p><FiMail /><a href='mailto:project@gandyengineering.com'>project@gandyengineering.com</a></p>
          </div>
          <div className='info-hr'>
            <h5>Contract Administration & Supervision Department</h5>
            <p><FiMail /><a href='mailto:contract@gandyengineering.com'>contract@gandyengineering.com</a></p>
          </div>
          <div className='info-hr'>
            <h5>Finance and Administration Department</h5>
            <p><FiMail /><a href='mailto:finance@gandyengineering.com'>finance@gandyengineering.com</a></p>
          </div>
        </form>
      </section>
      <section id='map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23113.744509573648!2d38.78345071928139!3d9.001595272988647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b850c5f945c95%3A0xfce021b82b64d64!2sG%26Y%20Engineering%20Consult%20plc!5e0!3m2!1sen!2set!4v1666259337553!5m2!1sen!2set" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </section>
    </main>
  )
}

export default Contact